import {
    Box,
    Button,
    DialogActions,
    IconButton,
    Slide,
    Typography,
    TextField,
    CircularProgress,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Avatar,
    InputAdornment,
    Tooltip,
    Stepper,
    Step,
    StepLabel,
    useMediaQuery,
    useTheme,
    // FormControl,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { colorPalette } from 'styles/mainTheme'
import * as yup from 'yup'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import { forwardRef, useState, useEffect, useContext } from 'react'
import { useSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { userApi } from 'store/modules/user'
import _ from 'lodash'
import { getHostname } from 'utils/general'
import { useGetContactsQuery } from 'store/modules/activity/activity.query'
import { masterApiSlice } from 'store/modules/master/master.query'
import { useCreateUniversalInviteMutation } from 'store/modules/opportunity/opportunity.query'
import { getModalContext } from '../provider'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
// @ts-ignore:next-line
const Transition = forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const UniversalInviteDialog = () => {
    const useStyles = makeStyles(theme => ({
        chipInput: {
            border: 'none',
            borderRadius: 4,
            height: 'auto',
            '& .MuiChip-label': {
                color: colorPalette.white,
            },
        },
        autocomplete: {
            '& .MuiChip-label': {
                color: colorPalette.white,
            },
            '& .MuiFilledInput-root': {
                marginTop: '-10px',
                backgroundColor: colorPalette.white,
            },
            '& .MuiOutlinedInput-root': {
                padding: 0,
            },
        },
        errorText: {
            fontSize: 12,
        },
        formControl: {
            margin: theme.spacing(1),
        },
        inputLabel: { transform: 'translate(13px, -5.5px) scale(0.75)', zIndex: 10, backgroundColor: 'white' },
        paper: { minWidth: '600px' },
        chipStyle: {
            '& .MuiChip-label': {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: `${colorPalette.primary} !important`,
                borderRadius: '5px',
            },
        },
        switch: {
            '& .MuiSwitch-input': {
                maxWidth: 'initial',
            },
        },
        customRadio: {
            marginLeft: '1rem',
            borderRadius: '5px',
            padding: '3rem 2rem 1rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            cursor: 'pointer',
        },
        customCheckmark: {
            display: 'inline',
            position: 'absolute',
            right: '5px',
            top: '5px',
            alignSelf: 'end',
            color: colorPalette.green2,
        },
    }))

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
    })

    const [emailChips, setEmailChips] = useState<any>([])
    const [localError, setLocalError] = useState<{ [key: string]: string }>({})

    const [universalInvite, { isLoading: isLoadingUniversalInvite }] = useCreateUniversalInviteMutation()

    const { data: userProfile } = userApi.endpoints.getUserProfile.useQueryState({})

    const { onClose, isOpen, category, props } = useContext(getModalContext())

    useEffect(() => {
        if (emailChips[0]?.length === 0 || !emailChips) setLocalError({ Email: 'Required' })
    }, [emailChips, emailChips.length])

    const handleChangeAutocomplete = async (event, value) => {
        setLocalError({})
        const formattedEmail = value.map(item => {
            return typeof item === 'object' ? item.email : item
        })
        setEmailChips(formattedEmail)
    }

    const handleInputChange = async e => {
        setLocalError({})
    }

    const handleBlur = async e => {
        setLocalError({})
        if (e.target.value.length > 0) {
            setEmailChips([e.target.value])
        }
        const Email = emailChips[0] ? emailChips[0] : e.target.value

        await InviteSchema.validate(
            {
                Email: Email,
            },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setLocalError({})
                return true
            })
            .catch(e => {
                const currentLocalError = {}
                e.inner.forEach(e => {
                    currentLocalError[e.path] = e.message
                })
                setLocalError(currentLocalError)
                return false
            })
    }

    const handleUserData = event => {
        setLocalError({ ...localError, [event.target.name]: '' })
        setUserData({ ...userData, [event.target.name]: event.target.value })
    }

    const { enqueueSnackbar } = useSnackbar()

    const InviteSchema: any = yup.object().shape({
        Email: yup
            .string()
            .email(({ value }) => `${value} is not a valid email`)
            .required('Required'),
        firstName: yup
            .string()
            .required('First name is required')
            .matches(/^\s*\S[\s\S]*$/, '* This field cannot contain only blankspaces'),
        lastName: yup
            .string()
            .required('Last name is required')
            .matches(/^\s*\S[\s\S]*$/, '* This field cannot contain only blankspaces'),
    })

    const classes = useStyles()

    function stringAvatar(fullname: string) {
        return {
            children: fullname
                .split(' ')
                .map(n => n[0])
                .join(''),
        }
    }

    const hostname = getHostname()

    const { data: whitelabelInfoData } = masterApiSlice.endpoints.getWhiteLabelInfo.useQueryState(
        { website: hostname },
        { skip: !hostname },
    )

    const currentUserCompanyId = userProfile?.profilePart?.company?.ContentItemId
    const { refetch: refetchContacts } = useGetContactsQuery({
        params: {
            limit: 50,
            is_active: true,
            company__content_item_id: currentUserCompanyId,
            company__isnull: false,
            search: '',
        },
    })

    const isWhitelabel = whitelabelInfoData?.whiteLabelInfo ? true : false
    const isWLPAdmin = userProfile?.user?.roleNames?.includes('WlpAdmin')
    const isOwner = whitelabelInfoData?.companyId === currentUserCompanyId

    // const isWhitelabel = true
    // const isWLPAdmin = true
    // const isOwner = true

    const handleClose = () => {
        setLocalError({})
        setEmailChips([])
        setUserData({
            firstName: '',
            lastName: '',
        })
        onClose()
        if (isWLPAdmin && isOwner) {
            setActiveStep(0)
        } else {
            setActiveStep(1)
        }
    }

    function getSteps() {
        return ['Select invite type', 'Fill user details']
    }

    const [activeStep, setActiveStep] = useState(0)

    const steps = getSteps()

    useEffect(() => {
        if (props.isInviteCompany) setActiveStep(1)
    }, [props.isInviteCompany])

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            const isValidInput = await InviteSchema.validate(
                { Email: emailChips[0], firstName: userData.firstName, lastName: userData.lastName },
                {
                    abortEarly: false,
                },
            )
                .then(() => {
                    setLocalError({})
                    return true
                })
                .catch(e => {
                    const currentLocalError = {}
                    e.inner.forEach(e => {
                        currentLocalError[e.path] = e.message
                    })
                    setLocalError(currentLocalError)
                    return false
                })

            if (!isValidInput) return
            universalInvite(generateParams())
                .unwrap()
                .then(() => {
                    enqueueSnackbar('User Invited Successfully!', { variant: 'success' })

                    refetchContacts()

                    onClose()
                    setEmailChips([])
                    setUserData({
                        firstName: '',
                        lastName: '',
                    })
                })
                .catch((err: any) => {
                    console.log(err, 'ERR')
                    enqueueSnackbar(`An error occurred`, { variant: 'error' })
                })
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const [selectedPath, setSelectedPath] = useState('inviteCompany')
    const [selectedUserType, setSelectedUserType] = useState('Originator')

    const [role, setRole] = useState('StandardUser')

    const handleChangeRole = event => {
        setRole(event.target.value)
    }

    const generateParams = () => {
        if (selectedPath === 'inviteCompany') {
            return {
                email: emailChips[0]?.toLowerCase(),
                firstName: userData.firstName,
                lastName: userData.lastName,
                coreTeamId: userProfile?.coreTeamIds?.[0],
                userType: selectedUserType,
                roleType: role !== 'StandardUser' ? [role] : [],
            }
        }
        if (selectedPath === 'inviteWhitelabel') {
            return {
                email: emailChips[0]?.toLowerCase(),
                firstName: userData.firstName,
                lastName: userData.lastName,
                userType: selectedUserType,
                roleType: role !== 'StandardUser' ? [role] : [],
            }
        }
    }

    useEffect(() => {
        if (isWLPAdmin && isOwner) {
            setActiveStep(0)
        } else {
            setActiveStep(1)
        }
    }, [isOwner, isWLPAdmin, isWhitelabel])

    if (isOpen && category === 'universal-invite') {
        return (
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="request-access-modal"
                scroll="body"
                classes={{ paper: classes.paper }}
                fullWidth
                fullScreen={isSmall ? true : false}
            >
                <DialogTitle style={{ color: '#003B4C', border: 'none', padding: '25px 25px 0px' }} id="alert-dialog-title">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">Invite Users to {userProfile?.profilePart?.company?.DisplayText}</Typography>

                        <IconButton onClick={handleClose}>
                            <CloseSharpIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{ paddingTop: '25px', minHeight: '350px' }}>
                    <div>
                        {isWLPAdmin && isOwner && (
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps: any = {}
                                    const labelProps: any = {}

                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        )}

                        <div>
                            <div style={{ padding: '20px' }}>
                                {activeStep === 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div
                                            className={classes.customRadio}
                                            style={{
                                                border:
                                                    selectedPath === 'inviteCompany'
                                                        ? `2px solid ${colorPalette.green2}`
                                                        : '2px solid lightgray',
                                            }}
                                            onClick={() => setSelectedPath('inviteCompany')}
                                        >
                                            {selectedPath === 'inviteCompany' ? (
                                                <CheckCircleIcon className={classes.customCheckmark} />
                                            ) : null}

                                            <div
                                                style={{
                                                    userSelect: 'none',
                                                    maxWidth: '300px',
                                                    minHeight: '200px',
                                                }}
                                            >
                                                <Typography
                                                    variant="h2"
                                                    style={{
                                                        color: selectedPath === 'inviteCompany' ? colorPalette.primary : 'gray',
                                                    }}
                                                >
                                                    Company
                                                </Typography>
                                                <Typography variant="body1" style={{ paddingTop: '20px' }}>
                                                    Invite employees to join your company to help manage your transactions.
                                                </Typography>
                                            </div>
                                        </div>
                                        {isWLPAdmin && isOwner && (
                                            <div
                                                className={classes.customRadio}
                                                style={{
                                                    border:
                                                        selectedPath === 'inviteWhitelabel'
                                                            ? `2px solid ${colorPalette.green2}`
                                                            : '2px solid lightgray',
                                                }}
                                                onClick={() => setSelectedPath('inviteWhitelabel')}
                                            >
                                                {selectedPath === 'inviteWhitelabel' ? (
                                                    <CheckCircleIcon className={classes.customCheckmark} />
                                                ) : null}

                                                <div
                                                    style={{
                                                        userSelect: 'none',
                                                        maxWidth: '300px',
                                                        minHeight: '200px',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h2"
                                                        style={{
                                                            color: selectedPath === 'inviteWhitelabel' ? colorPalette.primary : 'gray',
                                                        }}
                                                    >
                                                        Whitelabel
                                                    </Typography>
                                                    <Typography variant="body1" style={{ paddingTop: '20px', color: 'gray' }}>
                                                        Invite Investors or Borrowers to join your whitelabel. <br></br>
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeStep === 1 && (
                                    <>
                                        {selectedPath === 'inviteWhitelabel' && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    margin: '1.5rem auto',

                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <div
                                                    className={classes.customRadio}
                                                    style={{
                                                        border:
                                                            selectedUserType === 'Originator'
                                                                ? `2px solid ${colorPalette.green2}`
                                                                : '2px solid lightgray',
                                                    }}
                                                    onClick={() => setSelectedUserType('Originator')}
                                                >
                                                    {selectedUserType === 'Originator' ? (
                                                        <CheckCircleIcon className={classes.customCheckmark} />
                                                    ) : null}

                                                    <div
                                                        style={{
                                                            userSelect: 'none',
                                                            maxWidth: '300px',
                                                            minHeight: '150px',
                                                        }}
                                                    >
                                                        <Typography variant="h2">Add Deals</Typography>
                                                        <Typography variant="body1" style={{ paddingTop: '20px' }}>
                                                            Invite a user to create a new deal on your whitelabel.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div
                                                    className={classes.customRadio}
                                                    style={{
                                                        border:
                                                            selectedUserType === 'Investor'
                                                                ? `2px solid ${colorPalette.green2}`
                                                                : '2px solid lightgray',
                                                    }}
                                                    onClick={() => setSelectedUserType('Investor')}
                                                >
                                                    {selectedUserType === 'Investor' ? (
                                                        <CheckCircleIcon className={classes.customCheckmark} />
                                                    ) : null}

                                                    <div
                                                        style={{
                                                            userSelect: 'none',
                                                            maxWidth: '300px',
                                                            minHeight: '150px',
                                                        }}
                                                    >
                                                        <Typography variant="h2">View Deal Rooms</Typography>
                                                        <Typography variant="body1" style={{ paddingTop: '20px' }}>
                                                            Invite a user to view a list of available deal rooms only.
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <Typography variant="body1" style={{ paddingBottom: '20px' }}>
                                                <b>Step 1: Enter new user information.</b>
                                                <Tooltip title="Enter the email address, first and last name of the new user.">
                                                    <InfoIcon style={{ color: '#4299E1', paddingLeft: '5px' }} />
                                                </Tooltip>
                                            </Typography>
                                            {selectedPath !== 'inviteWhitelabel' && (
                                                <>
                                                    <Typography variant="body2" style={{ paddingBottom: '5px' }}>
                                                        Role
                                                    </Typography>
                                                    <Select
                                                        value={role}
                                                        onChange={handleChangeRole}
                                                        style={{ minHeight: '70px', width: '100%' }}
                                                    >
                                                        <MenuItem value={'StandardUser'}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <ListItemIcon
                                                                    style={{ minWidth: '50px', minHeight: '50px', marginRight: '10px' }}
                                                                >
                                                                    <AccountCircleIcon
                                                                        style={{ minWidth: '50px', minHeight: '50px', marginRight: '10px' }}
                                                                    />
                                                                </ListItemIcon>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
                                                                                Member
                                                                            </Typography>
                                                                        }
                                                                    />

                                                                    <ListItemText secondary="Access to specified deals only.  [Limited Features]" />
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                        <MenuItem value={'TeamAdmin'}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <ListItemIcon
                                                                    style={{ minWidth: '50px', minHeight: '50px', marginRight: '10px' }}
                                                                >
                                                                    <SupervisorAccountIcon
                                                                        style={{ minWidth: '50px', minHeight: '50px', marginRight: '10px' }}
                                                                    />
                                                                </ListItemIcon>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
                                                                                Admin
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                    <ListItemText secondary="Access to all deals.  [Full Features]" />
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    </Select>
                                                </>
                                            )}
                                            <Typography variant="body2" style={{ paddingBottom: '5px', marginTop: '14px' }}>
                                                Email
                                            </Typography>
                                            <Autocomplete
                                                // loading={isLoadingMyContacts || isFetchingMyContacts}
                                                value={emailChips}
                                                multiple
                                                // options={_.isEmpty(emailChips) ? filteredContacts : []}
                                                options={[]}
                                                getOptionLabel={option => option.email}
                                                renderOption={option => (
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ marginRight: '1rem' }}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: colorPalette.primary,
                                                                    fontSize: 18,
                                                                    height: '45px',
                                                                    width: '45px',
                                                                    margin: '6px 0px',
                                                                }}
                                                                {...stringAvatar(`${option.firstName} ${option.lastName}`)}
                                                            />
                                                        </div>
                                                        <div style={{ marginRight: 10, width: '100%', padding: '6px 0px' }}>
                                                            <span style={{ display: 'block' }}>
                                                                {`${option.firstName} ${option.lastName}`}{' '}
                                                            </span>
                                                            <span style={{ display: 'block' }}>{option?.email}</span>
                                                        </div>

                                                        {userProfile?.profilePart?.company?.DisplayText === option.company[0]?.name && (
                                                            <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                                                                <Chip size="small" className={classes.chipStyle} label="My Company" />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                freeSolo
                                                onChange={handleChangeAutocomplete}
                                                disabled={props.isLoadingMyContacts}
                                                className={classes.autocomplete}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => {
                                                        return (
                                                            <Chip
                                                                variant="outlined"
                                                                style={{
                                                                    backgroundColor: colorPalette.secondary,
                                                                }}
                                                                label={typeof option === 'string' ? option : option.email}
                                                                {...getTagProps({ index })}
                                                            />
                                                        )
                                                    })
                                                }
                                                onInputChange={e => handleInputChange(e)}
                                                renderInput={params => {
                                                    return (
                                                        <TextField
                                                            type="email"
                                                            {...params}
                                                            InputProps={
                                                                props.isLoadingMyContacts
                                                                    ? {
                                                                          ...params.InputProps,
                                                                          startAdornment: (
                                                                              <InputAdornment position="start">
                                                                                  <CircularProgress
                                                                                      size={20}
                                                                                      style={{
                                                                                          color: colorPalette.primary,
                                                                                          marginLeft: '14px',
                                                                                      }}
                                                                                  />
                                                                              </InputAdornment>
                                                                          ),
                                                                      }
                                                                    : _.isEmpty(emailChips)
                                                                    ? { ...params.InputProps, style: { paddingLeft: '10px' } }
                                                                    : {
                                                                          ...params.InputProps,
                                                                          readOnly: true,
                                                                          style: { paddingLeft: '10px' },
                                                                      }
                                                            }
                                                            variant="outlined"
                                                            onBlur={e => handleBlur(e)}
                                                            disabled={emailChips.length > 0}
                                                        />
                                                    )
                                                }}
                                            />
                                            <Typography color="error" className={classes.errorText}>
                                                {localError['Email']}
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" style={{ paddingBottom: '5px', marginTop: '14px' }}>
                                            First Name
                                        </Typography>
                                        <TextField
                                            style={{ width: '100%' }}
                                            value={userData.firstName}
                                            onChange={e => handleUserData(e)}
                                            name="firstName"
                                        />
                                        <Typography color="error" className={classes.errorText}>
                                            {localError['firstName']}
                                        </Typography>

                                        <Typography variant="body2" style={{ paddingBottom: '5px', marginTop: '14px' }}>
                                            Last Name
                                        </Typography>
                                        <TextField
                                            style={{ width: '100%' }}
                                            value={userData.lastName}
                                            onChange={e => handleUserData(e)}
                                            name="lastName"
                                        />
                                        <Typography color="error" className={classes.errorText}>
                                            {localError['lastName']}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions style={{ border: 'none', padding: '25px' }}>
                    <div style={{ marginTop: '1rem' }}>
                        {isWLPAdmin && isOwner && (
                            <Button
                                size="large"
                                variant="contained"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                style={{ marginRight: '1rem' }}
                            >
                                Back
                            </Button>
                        )}
                        <Button size="large" variant="contained" color="primary" onClick={handleNext}>
                            {(props.isLoadingCreateUserRole || props.isLoading || isLoadingUniversalInvite) && (
                                <CircularProgress size={16} style={{ marginRight: 6, color: colorPalette.white }} />
                            )}
                            {activeStep === steps.length - 1 ? 'Invite' : 'Next'}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
    return <></>
}

export default UniversalInviteDialog
